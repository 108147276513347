:root {
  --main-dark-blue: #00002e;
  --main-blue: #4b7aff;
  --main-orange: #ff4215;
  --main-gold: #ff9930;
  --secondary-orange:#ff8156;
  --secondary-blue:#aad2ff;
  --gradient-blue-1:#64a9ff;
  --gradient-blue-2:#4137ff;
  --white: rgba(245, 249, 250, 1);
  --fill-bkgd:var(--main-gold);
  --fill-color:var(--white);
  --root-bkgd: var(--main-orange);
  --root-color: var(--white);
  --main-bkgd: #f3f5fc;
  --main-color: var(--main-dark-blue);
  --nav-bkgd: rgba(245, 249, 250, .9);
  --nav-color: var(--white);
  --header-color: var(--main-blue);
  --subheader-color: var(--main-orange);
  --link-color: var(--main-orange);
  --link-color-active: var(--main-gold);
  --headband-bkgd: var(--main-orange);
  --headband-color: var(--white);
  --cta-bkgd: var(--main-orange);
  --cta-color: var(--white);
  --masthead-header-color: var(--main-orange);
  --masthead1: var(--main-blue);
  --masthead2: var(--main-orange);
  --masthead3: var(--secondary-blue);
  --pricecard-bkgd: var(--main-blue);
  --pricecard-color: var(--white);
  --border-color: var(--main-gold);
  --accent-color: var(--main-orange); }

:root {
  --body-fonts: Brother-1816, century, verdana, sans-serif;
  --header-fonts: Kapra, Impact, sans-serif;
  --font-size: 1em; }

@media (min-width: 1200px) {
  :root {
    --font-size: 1.3em; } }

@font-face {
  font-family: 'Kapra';
  font-display: swap;
  src: url("/fonts/Kapra-Regular.woff2") format("woff2"), url("/fonts/Kapra-Regular.woff") format("woff"); }

@font-face {
  font-family: 'Kapra';
  font-display: swap;
  font-style: italic;
  src: url("/fonts/Kapra-Italic.woff2") format("woff2"), url("/fonts/Kapra-Italic.woff") format("woff"); }

@font-face {
  font-family: 'Kapra';
  font-display: swap;
  font-weight: bold;
  src: url("/fonts/Kapra-Bold.woff2") format("woff2"), url("/fonts/Kapra-Bold.woff") format("woff"); }

@font-face {
  font-family: 'Kapra';
  font-display: swap;
  font-style: italic;
  font-weight: bold;
  src: url("/fonts/Kapra-BoldItalic.woff2") format("woff2"), url("/fonts/Kapra-BoldItalic.woff") format("woff"); }

@font-face {
  font-family: 'Kapra';
  font-display: swap;
  font-weight: 900;
  src: url("/fonts/Kapra-ExtraBold.woff2") format("woff2"), url("/fonts/Kapra-ExtraBold.woff") format("woff"); }

@font-face {
  font-family: 'Kapra';
  font-display: swap;
  font-style: italic;
  font-weight: 900;
  src: url("/fonts/Kapra-ExtraBoldItalic.woff2") format("woff2"), url("/fonts/Kapra-ExtraBoldItalic.woff") format("woff"); }

@font-face {
  font-family: 'Brother-1816';
  font-display: swap;
  src: url("/fonts/Brother1816-Regular.woff2") format("woff2"), url("/fonts/Brother1816-Regular.woff") format("woff"); }

@font-face {
  font-family: 'Brother-1816';
  font-display: swap;
  font-style: italic;
  src: url("/fonts/Brother1816-Italic.woff2") format("woff2"), url("/fonts/Brother1816-Italic.woff") format("woff"); }

@font-face {
  font-family: 'Brother-1816';
  font-display: swap;
  font-weight: bold;
  src: url("/fonts/Brother1816-Bold.woff2") format("woff2"), url("/fonts/Brother1816-Bold.woff") format("woff"); }

@font-face {
  font-family: 'Brother-1816';
  font-display: swap;
  font-style: italic;
  font-weight: bold;
  src: url("/fonts/Brother1816-BI.woff2") format("woff2"), url("/fonts/Brother1816-BI.woff") format("woff"); }

@font-face {
  font-family: 'Brother-1816';
  font-display: swap;
  src: url("/fonts/Brother1816-Medium.woff2") format("woff2"), url("/fonts/Brother1816-Medium.woff") format("woff");
  font-weight: 500; }

@font-face {
  font-family: 'Brother-1816';
  font-display: swap;
  src: url("/fonts/Brother1816-MediumItalic.woff2") format("woff2"), url("/fonts/Brother1816-MediumItalic.woff") format("woff");
  font-style: italic;
  font-weight: 500; }

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

html {
  background-color: var(--root-bkgd);
  color: var(--root-color); }

body {
  background-color: var(--main-bkgd);
  color: var(--main-color);
  font: var(--font-size)/1.6 var(--body-fonts); }

*::selection {
  background-color: var(--main-blue);
  color: var(--white); }

input:focus, textarea:focus, select:focus, button:focus {
  outline-color: var(--main-blue); }

a:link, a:visited {
  color: var(--link-color); }

a:hover, a:active {
  color: var(--link-color-active); }

label {
  cursor: pointer; }

.cta {
  background: transparent;
  border: 3px solid currentColor;
  color: var(--cta-color);
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  font-weight: bold;
  line-height: 1;
  padding: 1rem;
  text-transform: uppercase; }

.cta:link, .cta:visited {
  color: inherit;
  text-decoration: none; }

.cta:hover, .cta:active {
  color: inherit;
  text-decoration: none; }

.wrapper {
  display: flex;
  flex-flow: column;
  min-height: 100vh; }

.section-fill {
  background: var(--fill-bkgd);
  margin: 0 -3rem;
  padding: 3rem; }

.section-fill .section-head, .section-fill .sub-head {
  color: var(--fill-color); }

.header {
  background: var(--nav-bkgd);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.2);
  display: flex;
  padding: 0 3rem;
  position: static;
  top: 0;
  z-index: 5;
  flex-flow: row wrap; }

.no-js .header {
  position: static; }

h1 {
  display: flex;
  text-align: center;
  width: 100%;
  flex: 1 1 100%; }

.homelink {
  --offset: 0;
  margin: 1.6rem auto;
  position: relative;
  top: var(--offset);
  align-self: center;
  display: flex; }

.homelink img {
  max-height: 125px; }

.headband {
  background-color: var(--headband-bkgd);
  color: var(--headband-color);
  font-weight: bold;
  width: 100vw;
  order: -1;
  margin: 0 -3rem;
  padding: .25rem 4rem;
  align-content: center;
  text-align: center; }

.headband a {
  color: currentColor;
  text-decoration: none;
  white-space: nowrap; }

.nav {
  align-self: center;
  flex: 1 1 auto;
  text-align: right; }

.nav-list {
  list-style: none;
  display: none;
  flex-flow: column nowrap; }

.nav-item {
  display: inline-block; }

.nav-item a {
  color: currentColor;
  cursor: pointer;
  display: block;
  font-weight: bold;
  padding: 1rem;
  text-decoration: none;
  transition: transform .15s ease-in; }

.nav-item a:hover, .nav-item a:active {
  transform: translateY(-0.25rem); }

@media (min-width: 500px) {
  h1 {
    width: auto;
    flex: 0 0 auto; }
  .header {
    flex-direction: row;
    position: sticky; }
  .homelink {
    --offset: -20px;
    margin: 0; }
  .headband {
    text-align: right; }
  .nav-list {
    display: block; } }

.main {
  display: flex;
  flex: 1;
  flex-flow: column;
  padding: 0 3rem; }

.main > section {
  margin-bottom: 4.8rem; }

.main p {
  margin-bottom: 1.6rem; }

.section-head {
  color: var(--header-color);
  font-family: var(--header-fonts);
  font-size: 3rem;
  line-height: 1;
  margin-bottom: 1.6rem;
  text-transform: uppercase; }

.section-intro {
  margin-bottom: 3.2rem; }

.sub-head {
  color: var(--subheader-color);
  font-size: 2rem;
  font-family: var(--header-fonts);
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 1.6rem; }

.masthead {
  --header-color: var(--white);
  align-items: flex-start;
  background: linear-gradient(160deg, var(--masthead1), var(--masthead2), var(--masthead3)), url(/i/teenyhexes.png) repeat var(--fill-bkgd);
  background-blend-mode: screen, multiply;
  background-blend-mode: overlay, multiply;
  display: flex;
  min-height: 25vh;
  margin: 0 -3rem 1rem;
  padding: 3rem;
  flex-flow: column nowrap; }

.masthead h2 {
  font: normal 6rem/0.85 var(--header-fonts);
  text-shadow: 1px 1px 0 var(--main-orange);
  text-align: center;
  flex: 0 1 10%; }

.masthead .copy {
  flex: 1;
  max-width: 50rem; }

.masthead .copy > p {
  margin-bottom: 1rem; }

.masthead .cta {
  color: var(--header-color);
  display: block;
  text-align: center; }

@media (min-width: 500px) {
  .masthead {
    flex-flow: row nowrap; }
  .masthead h2 {
    text-align: left; }
  .masthead .copy {
    margin-left: 3.2rem; }
  .masthead .cta {
    display: inline-block; } }

@media (min-width: 960px) {
  .masthead {
    align-items: center; } }

.servicecards {
  display: flex;
  flex-flow: column nowrap;
  max-width: 150rem; }

.servicecard {
  flex: 1;
  margin-bottom: 1.6rem; }

.servicecard:last-of-type {
  margin-bottom: 0; }

.servicecard:last-of-type p:last-of-type {
  margin-bottom: 0; }

@media (min-width: 960px) {
  .servicecards {
    flex-direction: row; }
  .servicecard {
    margin-bottom: 0; }
  .servicecard:nth-of-type(2) {
    border-color: var(--border-color);
    border-style: solid;
    border-width: 0 2px;
    margin: 0 3.2rem;
    padding: 0 3.2rem; } }

.pricecards {
  display: flex;
  flex-flow: column nowrap;
  max-width: 150rem; }

.pricecard {
  flex: 1;
  margin-bottom: 1.6rem; }

.price {
  font: bolder 5rem/1 var(--body-fonts);
  margin-bottom: 1.6rem; }

.pricecard-copy {
  flex: 1;
  padding: 0; }

.pricecard-list {
  list-style: none;
  margin-bottom: 1.6rem; }

.pricecard-list > li {
  background: 0 0.2rem/0.8rem 0.8rem url("/i/icons.svg#bg-hex") no-repeat;
  line-height: 1.15;
  margin-bottom: .6rem;
  padding-left: 1.6rem; }

.pricecard-list > li:last-of-type {
  margin-bottom: 0; }

.pricecard-list li[data-foot]::after {
  color: var(--accent-color);
  content: attr(data-foot);
  display: inline-block;
  font-size: .65rem;
  margin-left: .25rem;
  position: absolute;
  vertical-align: super; }

.footnotes {
  font-size: 85%;
  font-style: italic;
  margin: 1.6rem 1rem 0;
  padding: 0; }

@media (min-width: 500px) {
  .pricecard-copy {
    display: flex;
    flex-flow: row nowrap; }
  .price {
    margin-right: 3.2rem; } }

@media (min-width: 960px) {
  .pricecards {
    flex-flow: row nowrap;
    margin-bottom: 3.2rem; }
  .pricecard {
    flex: 1 1 100%;
    margin-bottom: 0; }
  .pricecard:nth-of-type(2) {
    border-color: var(--border-color);
    border-style: solid;
    border-width: 0 2px;
    margin: 0 3.2rem;
    padding: 0 3.2rem; }
  .pricecard-copy {
    display: flex;
    flex-flow: column nowrap; }
  .price {
    margin-right: 0;
    text-align: center; }
  .pricecard-list {
    margin-bottom: 0; } }

#mc_embed_signup {
  margin-top: 3.2rem; }

#mc_embed_signup_scroll {
  align-content: bottom;
  display: flex; }

#mc_embed_signup_scroll label {
  display: block;
  font-weight: bold;
  position: absolute;
  transform: translateY(-100%); }

#mc_embed_signup_scroll input[type='email'] {
  border-width: 0;
  color: currentColor;
  font-family: inherit;
  font-size: inherit;
  height: 100%;
  line-height: 1;
  padding: 0 1.6rem;
  width: 100%; }

#mc_embed_signup_scroll .mc-field-group {
  flex: 1 1 100%; }

#mc_embed_signup_scroll .clear {
  display: flex;
  flex: 0 0 auto;
  margin-left: .25rem; }

.contact-list {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  line-height: 1; }

.contact-list .sub-head {
  margin-bottom: 0; }

.contact-list dd {
  white-space: nowrap; }

.contact-list a:link, .contact-list a:visited {
  color: var(--main-color);
  text-decoration: none; }

.contact-list a:hover, .contact-list a:active {
  color: var(--link-color-active);
  text-decoration: underline; }

.contact-card {
  margin-bottom: 1.6rem; }

.contact-card:last-of-type {
  margin-bottom: 0; }

.social-list {
  align-content: center;
  display: flex;
  flex-flow: row nowrap;
  list-style: none; }

.social-list li {
  margin-right: 1.25rem; }

.social-list li:first-of-type {
  margin-left: -.25rem; }

.social-list li:last-of-type {
  margin-right: 0; }

.social-list a {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  padding: .25rem .35rem;
  text-decoration: none; }

.social-list span {
  display: none; }

.social-list svg {
  fill: currentColor;
  height: 2rem;
  width: 2rem; }

.social-list.text-display {
  flex-direction: column; }

.social-list.text-display a {
  padding: .25rem 0; }

.social-list.text-display span {
  display: inline-block;
  margin-left: .5rem; }

.social-list.text-display svg {
  height: 1.6rem;
  width: 1.6rem; }

@media (min-width: 500px) {
  .contact-card {
    display: flex;
    flex-flow: row wrap; }
  .contact-card .sub-head {
    flex: 0 0 15%;
    margin-right: 1.6rem; }
  .contact-card dd {
    flex: 1 1 auto;
    justify-content: flex-end; }
  .contact-card a {
    vertical-align: top; } }

@media (min-width: 960px) {
  .contact-list {
    flex-flow: row nowrap; }
  .contact-card {
    border-right: 2px solid var(--border-color);
    flex: 1 1 auto;
    margin-right: 1.6rem;
    padding-right: 1.6rem; }
  .contact-card:last-of-type {
    border-width: 0;
    margin: 0;
    padding: 0; }
  .social-list li {
    margin-right: 0; } }

@media (min-width: 1200px) {
  dd {
    font-size: 1.6rem; } }

.giving-copy p:last-of-type {
  margin-bottom: 0; }

.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 3rem; }

h4 {
  font-size: 1rem; }

.bio {
  margin-bottom: 1.6rem; }

.meta {
  flex: 0 1 auto; }

.disclaimer {
  background: var(--root-bkgd);
  color: var(--root-color);
  flex: 1 1 100%;
  font-size: .75rem;
  font-style: italic;
  line-height: 1.3;
  margin: 1.6rem -3rem 0;
  padding: 1rem 3rem; }

@media (min-width: 960px) {
  .footer {
    display: flex; }
  .bio {
    flex: 0 1 50%;
    margin: 0; } }

@media (min-width: 1200px) {
  .bio {
    flex-basis: 60%; } }

@media (min-width: 960px) {
  .copyblock p:last-of-type {
    margin-bottom: 0; } }
